<template>
  <!--  社区首页内容管理-->
  <div class="communityContent">
    <a-spin :indicator="indicator" :spinning="spinning">
      <!--            <div class="communityContent_header">-->
      <!--      &lt;!&ndash;        <div class="header_select" style="margin-right: 5%">&ndash;&gt;-->
      <!--      &lt;!&ndash;          状态: &nbsp;<a-radio-group button-style="solid" v-model="status" @change="statusBtn">&ndash;&gt;-->
      <!--      &lt;!&ndash;          <a-radio-button :value="1">&ndash;&gt;-->
      <!--      &lt;!&ndash;            已发布&ndash;&gt;-->
      <!--      &lt;!&ndash;          </a-radio-button>&ndash;&gt;-->
      <!--      &lt;!&ndash;          <a-radio-button :value="819">&ndash;&gt;-->
      <!--      &lt;!&ndash;            已置顶&ndash;&gt;-->
      <!--      &lt;!&ndash;          </a-radio-button>&ndash;&gt;-->
      <!--      &lt;!&ndash;          <a-radio-button :value="0">&ndash;&gt;-->
      <!--      &lt;!&ndash;            未发布&ndash;&gt;-->
      <!--      &lt;!&ndash;          </a-radio-button>&ndash;&gt;-->
      <!--      &lt;!&ndash;        </a-radio-group>&ndash;&gt;-->
      <!--      &lt;!&ndash;        </div>&ndash;&gt;-->
      <!--      &lt;!&ndash;        <div>&ndash;&gt;-->
      <!--&lt;!&ndash;                标题: &nbsp;<a-input v-model="searchName" placeholder="请输入" size="default"&ndash;&gt;-->
      <!--&lt;!&ndash;                                   style="width: 180px" @pressEnter="()=>this.searchOk()"/>&nbsp;&nbsp;&nbsp;&ndash;&gt;-->
      <!--&lt;!&ndash;                <a-button style="margin-left: 10px" type="primary" @click="searchOk">查询</a-button>&ndash;&gt;-->
      <!--&lt;!&ndash;                <a-button style="margin-left: 10px" @click="searchReset">重置</a-button>&ndash;&gt;-->
      <!--      &lt;!&ndash;        </div>&ndash;&gt;-->
      <!--            </div>-->
      <div class="communityContent_center">
        <!--        <a-button style="margin-left: 10px" @click="addBtn('TEXT','发布文字内容')">发布文字内容</a-button>-->
        <!--        <a-button style="margin-left: 10px" @click="addBtn('IMAGE','发布图片内容')">发布图片内容</a-button>-->
        <!--        <a-button style="margin-left: 10px" @click="addBtn('VIDEO','发布视频内容')">发布视频内容</a-button>-->
        <!--        <a-button style="margin-left: 10px" @click="addBtn('LINK','发布外链内容')">发布外链内容</a-button>-->
        <a-table size="middle" :scroll="{ x: 1500}" :columns="columns" style="margin-bottom: 35px" :data-source="InfoTable" :pagination="false" rowKey="id"
        >
          <!--        序号-->
          <template slot="index" slot-scope="text,row,index">
            {{index + ((pagination.current - 1) * pagination.pageSize) + 1}}
          </template>
          <span slot="type" slot-scope="text,item">
            {{
              item.type === 'TEXT' ? '社区-文字' : item.type === 'IMAGE' ? '社区-图片'
                  : item.type === 'LINK' ? '社区-外链' : item.type === 'VIDEO' ? '社区-视频'
                      : item.type === 'DOCUMENT' ? '社区-指南' : item.type === 'MAGAZINE' ? '社区-杂志' : item.type === 'RECRUITMENT' ? '社区-招聘' :
                          item.type === 'MIX_SET_TOPIC' ? '混合专题' : item.type === 'ARTICLE_SINGLE' ? '文章' : item.type === 'SERIES' ? '小会-系列' : item.type === 'SINGLE' ? '小会-普通'
                              : item.type === 'convention' ? '医学会议（多会场）' : item.type === 'VOD_SINGLE' ? '点播-单视频' : item.type
            }}
          </span>


          <!--          创建时间-->
          <!--          <span slot="created_time">-->
          <!--          <div class="timeIndex">-->
          <!--            <div class="title">创建时间</div>-->
          <!--            <div>-->
          <!--              <div style="height: 10px" @click="createdUp"><a-icon type="caret-up" :class="{ liBackground:provincesStyle === 'cUp'}"/></div>-->

          <!--              <div  @click="createdDown"><a-icon  type="caret-down" :class="{ liBackground:provincesStyle === 'cDown'}" /></div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          </span>-->
          <span slot="publish_time">
          <div class="timeIndex">
            <div class="title">发布时间</div>
            <!--            <div>-->
            <!--              <div style="height: 10px" @click="publishUp"><a-icon type="caret-up" :class="{ liBackground:provincesStyle === 'pUp'}" /></div>-->
            <!--              <div @click="publishDown"><a-icon  type="caret-down" :class="{ liBackground:provincesStyle === 'pDown'}" /></div>-->
            <!--            </div>-->
          </div>
          </span>
          <span slot="titles" slot-scope="text,item">
            <a-tag v-if="item.is_top===1" color="rgba(245, 34, 45, 1)">置顶</a-tag>
            <span>{{ text.title || text.ntitle }}</span>
          </span>
          <span slot="state" slot-scope="text,item">
          {{ item.isOpen === 1 ? '已发布' : '已下架' }}
          </span>
          <span slot="operate" slot-scope="text,item">
          <a v-if="item.type==='LINK' || item.type==='TEXT' ||item.type==='IMAGE' || item.type==='VIDEO'"
             style="margin-right: 10px" @click="shelves(item.id,item.isOpen)">{{
              item.isOpen === 0 ? '上架' : '下架'
            }}</a>
            <a-popconfirm
                v-else
                title="确定移除此条数据?"
                ok-text="确定"
                cancel-text="取消"
                @confirm="tableRemove(item.id)"
            >
              <a >移除</a>
            </a-popconfirm>
            <a style="margin-left: 15px" @click="top(item.id,item.is_top)">{{
                item.is_top === 0 ? '置顶' : '取消置顶'
              }} </a>
            <!--          <a  @click="promotions(item)">推广</a>-->
        </span>
        </a-table>
        <div style="display: flex;justify-content: space-between;bottom: 3.5rem;z-index: 3">
                  <span style="font-size: 17px;color: #929292">共 {{ pagination.total }} 条记录 第 {{
                      pagination.current
                    }} / {{ Math.ceil(pagination.total / pagination.pageSize) }} 页</span>
          <a-pagination v-model="pagination.current"
                        :total="pagination.total"
                        show-size-changer
                        show-quick-jumper
                        @showSizeChange="pageChange"
                        @change="pageChange"/>
        </div>
      </div>
      <!----------发布内容弹窗----------------->
      <a-drawer
          :title="addTitle"
          :visible="addShow"
          width="600"
          @close="()=>{this.addShow = false;this.addForm={isOpen: 1,is_top: 0,content:{}}}"
      >
        <a-form-model ref="addItem" :label-col="labelCol" :model="addForm.content" :rules="rules"
                      :wrapper-col="wrapperCol">
          <a-form-model-item v-if="this.addType==='TEXT'" label="内容" prop="value" key="value">
            <a-textarea v-model="addForm.content.value" auto-size placeholder="请输入" style="min-height: 100px"/>
          </a-form-model-item>
          <a-form-model-item v-if="this.addType==='IMAGE'||this.addType==='VIDEO'|| this.addType==='LINK'" label="标题"
                             prop="title" key="title">
            <a-textarea v-model="addForm.content.title" auto-size placeholder="请输入" style="min-height: 100px"/>
          </a-form-model-item>
          <a-form-model-item v-if="this.addType==='IMAGE'" label="图片" prop="images" key="images">
            <div class="uploadImg">
              <input
                  id="addImg"
                  ref="addImg"
                  accept="image/*"
                  style="width: 100%;height:100%;opacity: 0;"
                  type="file"
                  @change="uploadImg"/>
            </div>
            <a-row :gutter="1">
              <a-col
                  v-for="(img,index) in addForm.content.images"
                  :span="6"
                  class="gutter-row"
                  style="position: relative;margin: 10px 10px 0 0;">
                <div class="upload_gutter">
                  <a-icon
                      style="color: red;font-size: 20px;position:absolute;top: 10px;right: 2px"
                      type="close-circle"
                      @click="delImg(index)"/>
                  <img
                      v-if="img"
                      :src="img"
                      alt=""
                      style="width: 100%;height:100%"/>
                </div>
              </a-col>
            </a-row>
          </a-form-model-item>
          <a-form-model-item v-if="this.addType==='LINK'" label="图片" prop="cover_url" key="cover_url">
            <div style="display: flex">
              <div class="uploadImg">
                <input
                    id="addCover"
                    ref="addImg"
                    accept="image/*"
                    style="width: 100%;height:100%;opacity: 0;"
                    type="file"
                    @change="uploadImg"/>
              </div>
              <div class="upload_gutter" style="margin-left: 5px">
                <img
                    v-if="addForm.content.cover_url"
                    :src="addForm.content.cover_url"
                    alt=""
                    style="width: 100%;height:100%"/>
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item v-if="this.addType==='VIDEO'" label="视频" prop="url" key="url">
            <div class="uploadImg">
              <input
                  id="addVideo"
                  ref="addVideo"
                  accept="video/*"
                  style="width: 100%;height:100%;opacity: 0;"
                  type="file"
                  @change="uploadVideo($event)"/>
            </div>
            <div class="videoShow">
              <video
                  controls
                  v-if="addForm.content.url"
                  :src="addForm.content.url"
                  style="width: 100%;height:100%"></video>
            </div>
          </a-form-model-item>
          <a-form-model-item label="链接" v-if="this.addType==='LINK'">
            <a-input v-model="addForm.content.link" placeholder="请输入"/>
          </a-form-model-item>
          <a-form-model-item label="状态">
            <a-switch checked-children="发布" default-checked un-checked-children="" @change="stateSwitch"/>
          </a-form-model-item>
          <a-form-model-item label="置顶">
            <a-switch checked-children="是" default-un-checked un-checked-children="否" @change="topSwitch"/>
          </a-form-model-item>
        </a-form-model>

        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px"
                    @click="()=>{this.addShow = false;this.addForm={isOpen: 1,is_top: 0,content:{}}}">
            取消
          </a-button>
          <a-button type="primary" @click="addOk">
            确认
          </a-button>
        </div>
      </a-drawer>


      <a-drawer
          title="推广"
          :width="500"
          :visible="promotionsVisible"
          :body-style="{ paddingBottom: '80px' }"
          @close="promotionsClose"
      >
        <a-spin :spinning="promotionSpin">
          <div style="margin:20px">
            <a-table :pagination="false" :columns="appColumns" :data-source="appData" :row-key="record => record.code"
                     bordered>
          <span slot="is_open" slot-scope="text, record">
            <a-switch v-model="record.is_open" checked-children="是" un-checked-children="否"
                      @click="AppListSwitch(record.is_open,record)" default-checked/>
          </span>
            </a-table>
          </div>
        </a-spin>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="promotionsClose">
            关闭
          </a-button>
        </div>
      </a-drawer>


    </a-spin>
  </div>

</template>

<script>
import {update} from "@/utils/update";
import {
  delCommunityContent,
  getAppContent,
  getCommunityContent, getCommunityContentCreateByName,
  postCommunityContent,
  putCommunityContentOpen,
  putCommunityContentTop
} from "@/service/homePageFeatures_api";
import {
  delAppPromotion,
  get_signature,
  get_videoLink,
  getAppPromotion, getColumnVodsList, postAppPromotion
} from "@/service/MedicalConference_y";

export default {
  name: "communityContent",
  data() {
    return {
      spinning: false,
      addShow: false,
      editShow: false,
      promotionsVisible: false,
      appColumns: [
        {
          title: 'APP首页',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: {customRender: 'is_open'},
        },
      ],
      appData: [],
      indicator: <a-icon type="loading" style="font-size: 24px" spin/>,
      promotionSpin: false,
      labelCol: {span: 4},
      wrapperCol: {span: 16},
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      searchName: '',
      columns: [
        {
          title: '序号',
          fixed: 'left',
          align: 'center',
          width: 70,
          scopedSlots: {customRender: 'index'}
        },
        {
          title: '标题',
          scopedSlots: {customRender: 'titles'},
          width: '30%'
        },
        {
          title: '来源',
          scopedSlots: {customRender: 'type'}
        },
        {
          title: '发布时间',
          align: 'center',
          dataIndex: 'publish_time',
          sorter: (a, b) => {
            return a.publish_time > b.publish_time ? 1 : -1
          },
        },
        // {
        //   slots: { title: 'publish_time' },
        //   dataIndex: 'publish_time'
        // },
        {
          title: '状态',
          scopedSlots: {customRender: 'state'}
        },
        {
          title: '创建人',
          dataIndex: 'createName'
        },
        {
          title: '操作',
          width: 150,
          fixed: 'right',
          scopedSlots: {customRender: 'operate'}
        }],
      rules: {
        title: [{required: true, message: '请输入标题', trigger: 'blur'}],
        url: [{required: true, message: '请上传视频', trigger: 'blur'}],
        link: [{required: true, message: '请输入链接', trigger: 'blur'}],
        value: [{required: true, message: '请输入内容', trigger: 'blur'}],
        images: [{required: true, message: '需要上传图片', trigger: 'blur'}],
        cover_url: [{required: true, message: '需要上传图片', trigger: 'blur'}],
      },
      InfoTable: [],
      addForm: {
        content: {},
        isOpen: 1,
        is_top: 0,
      },
      editForm: {},
      addType: '',
      addTitle: '',
      editType: '',
      editTitle: '',
      status: null,
      timeIndex: '',
      provincesStyle: '',
      //上传视频
      fileId: '', // 文件id
      vFile: {}, // 视频文件File对象
      vIsFileExist: false, // File对象是否存在
      vUploader: {}, // 腾讯云上传的初始化对象
      signature: '',
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle', "社区首页内容管理")
  },
  mounted() {
    this.getCommunityInfo()
  },
  methods: {
    async tableRemove(id) { // 移除首页内容
      const response = await delCommunityContent(id)
      if (response.code === 0) {
        await this.getCommunityInfo()
        this.$message.success('移除成功!')
      } else {
        this.$message.warning(response.message)
      }
    },
    async getCommunityInfo() {
      this.spinning = true
      const info = await getCommunityContent(parseInt(this.pagination.current) - 1, this.pagination.pageSize)
      let createNameList = []
      const response = await getCommunityContentCreateByName(this.pagination.current, this.pagination.pageSize) // 获取创建人名字
      if (response.code === 0) {
        createNameList = response.data
      }else {
        this.$message.warning(response.message)
      }
      if (info.code === 0) {
        let data = {}
        let list = []
        console.log("info",info)
        info.data.rows.forEach((item,index) => {
          let itemD = item.childData
          if (itemD.content) { // 判断有content的状况
            data = {
              id: item.id,
              title: itemD.content.title,
              publish_time: item.publish_time,
              isOpen: item.is_open,
              type: item.sub_content_type,
              is_top: item.is_top,
            }
          } else {
            data = {
              id: item.id,
              title: itemD.title,
              publish_time: item.publish_time,
              isOpen: item.is_open,
              type: item.sub_content_type,
              is_top: item.is_top,
            }
          }
          switch (item.sub_content_type) {
            case 'TEXT':
              data.contentType = '社区-文字内容'
              break;
            case 'IMAGE':
              data.contentType = '社区-图片内容'
              break;
            case 'LINK':
              data.contentType = '社区-外链'
              break;
            case 'VIDEO':
              data.contentType = '社区-视频内容'
              break;
            case 'DOCUMENT':
              data.contentType = '社区-指南'
              break;
            case 'MAGAZINE':
              data.contentType = '社区-杂志'
              break;
            case 'RECRUITMENT':
              data.contentType = '社区-招聘'
              break;
            case 'MIX_SET_TOPIC':
              data.contentType = '点播-混合专题'
              break;
            case 'VOD_SINGLE':
              data.contentType = '点播-单视频'
              break;
            case 'ARTICLE_SINGLE':
              data.contentType = '文章'
              break;
            case 'SERIES':
              data.contentType = '直播-小会-系列'
              break;
            case 'SINGLE':
              data.contentType = '直播-小会-普通'
              break;
            case 'CONVENTION':
              data.contentType = '直播-医学会议（多会场）'
              break;
            default:
              data.contentType = '未知code值，请联系管理员'
          }
          data.createName = createNameList[index].createName
          list.push(data)
        })
        this.InfoTable = list
        // this.InfoTable=info.data.communityContents
        this.pagination.total = info.data.count
        this.spinning = false
      } else {
        this.$message.error("获取错误，请联系管理员~" + info.message);
      }
    },
    //状态搜索
    statusBtn(e) {
      this.getCommunityInfo(1, e.target.value)
      this.status = e.target.value
    },
    // cUp:创建时间升序 cDown 创建时间降序 pUp:发布时间升序 pDown 发布时间降序
    //创建时间排序
    // createdUp(){
    //   this.getCommunityInfo(this.pagination.current,'','','cUp')
    //   this.timeIndex='cUp'
    //   this.provincesStyle='cUp'
    // },
    // createdDown(){
    //   this.getCommunityInfo(this.pagination.current,'','','cDown')
    //   this.timeIndex='cDown'
    //   this.provincesStyle='cDown'
    // },
    // //发布时间排序
    // publishUp(){
    //   this.getCommunityInfo(this.pagination.current,'','','pUp')
    //   this.timeIndex='pUp'
    //   this.provincesStyle='pUp'
    // },
    // publishDown(){
    //   this.getCommunityInfo(this.pagination.current,'','','pDown')
    //   this.timeIndex='pDown'
    //   this.provincesStyle='pDown'
    // },
    //确认搜索
    searchOk() {
      this.getCommunityInfo(1, '', this.searchName)
    },
    //搜索重置
    searchReset() {
      this.getCommunityInfo(1)
      this.searchName = null
      this.status = null
      this.timeIndex = ''
      this.provincesStyle = ''
    },
    onShowSizeChange(current, pageSize) { // 每页显示条目选择
      this.pagination.current = current
      this.pagination.pageSize = pageSize

    },
    //分页跳转
    pageChange(pageNo, pageSize) {
      this.pagination.current = pageNo
      this.pagination.pageSize = pageSize
      this.getCommunityInfo()
    },
    // 是否置顶btn
    async top(id, top) {
      const data = {
        isTop: top === 1 ? 0 : 1
      }
      switch (top) {
        case 0:
          const info = await putCommunityContentTop(id, data)
          if (info.code === 0) {
            await this.getCommunityInfo(this.pagination.current, this.status, this.searchName)
            this.$message.success('已置顶')
          }
          break;
        case 1:
          const info2 = await putCommunityContentTop(id, data)
          if (info2.code === 0) {
            await this.getCommunityInfo(this.pagination.current, this.status, this.searchName)
            this.$message.success('已取消置顶')
          }
          break;
      }
    },
    // 是否上下架btn
    async shelves(id, open) {
      const data = {
        isOpen: open === 1 ? 0 : 1
      }
      switch (open) {
        case 0:
          const info = await putCommunityContentOpen(id, data)
          if (info.code === 0) {
            await this.getCommunityInfo(this.pagination.current, this.status, this.searchName)
            this.$message.success('已上架')
          }
          break;
        case 1:
          const info2 = await putCommunityContentOpen(id, data)
          if (info2.code === 0) {
            await this.getCommunityInfo(this.pagination.current, this.status, this.searchName)
            this.$message.success('已下架')
          }
          break;
      }
    },

    //新增按钮
    addBtn(type, title) {
      this.addType = type
      this.addForm.type = type
      this.addTitle = title
      this.addShow = true
      if (type === 'IMAGE') {
        this.$set(this.addForm.content, 'images', [])
      }
    },
    //状态switch
    stateSwitch(checked) {
      checked === true ? this.addForm.isOpen = 1 : this.addForm.isOpen = 0
    },
    //置顶switch
    topSwitch(checked) {
      checked === true ? this.addForm.is_top = 1 : this.addForm.is_top = 0
    },
    //上传图片
    async uploadImg() {
      let file = this.$refs["addImg"].files[0]
      if (file !== undefined) {
        if (file.size < 2048576) {
          const image = await update(file);
          if (image.code === 0) {
            switch (this.addType) {
              case  'IMAGE':
                this.addForm.content.images.push(image.data.url)
                document.getElementById('addImg').value = null;
                break;
              case 'LINK' :
                this.$set(this.addForm.content, 'cover_url', image.data.url)
                document.getElementById('addCover').value = null;
                break;
            }
            this.$message.success("上传成功")
          } else {
            //  上传失败
            this.$message.error("上传失败，请重试。", image.message)
          }
        } else {
          this.$message.error("图片尺寸请小于2mb")
        }
      }
    },
    //获取签名
    async content_surface_plot_getSignature() {
      const response = await get_signature()
      if (response.code === 0) {
        this.signature = response.data.signature;
      }
    },
    //获取视频url地址
    async content_video_url(videoId) {
      const response = await get_videoLink(videoId)
      if (response.code === 0) {
        // setTimeout(function () {
        //   _this.$refs.video.src = response.data.mediaUrl
        // })
        this.$set(this.addForm.content, 'url', response.data.mediaUrl)
      } else {
        this.$message.warning(response.message)
      }
    },
    // 上传腾讯云
    uploadVideoFile_toyun() {
      let _this = this
      const getSignature = () => {
        return _this.signature;
      }
      const tcVod = new _this.$TcVod({
        getSignature: getSignature // 前文中所述的获取上传签名的函数
      })
      const mediaFile = this.vFile
      this.vUploader = tcVod.upload({
        mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
      })
      this.vUploader.done().then(res => { // 上传完成回调
        //视频上传后获取到的视频fileId
        this.addForm.content.file_id = res.fileId
        this.content_video_url(res.fileId)
        this.$message.success('视频文件上传成功')
      })
    },
    //上传视频
    async uploadVideo(e) {
      if (e.currentTarget.files[0]) { // 选择了文件
        if (e.currentTarget.files[0].type === 'video/mp4') {
          this.vFile = e.currentTarget.files[0] // 获取上传文件中的File对象信息
          document.getElementById('addVideo').value = null;
          await this.content_surface_plot_getSignature()
          await this.uploadVideoFile_toyun()
        } else {
          this.$message.warning('仅支持mp4格式的视频上传')
        }
      } else { // 取消选择文件
        this.vFile = {}
        this.vIsFileExist = false
      }
    },
    spreadOk() {

    },
    //新增确认
    async addOk() {
      this.$refs.addItem.validate(async valid => {
        if (valid) {
          if (this.addType === 'TEXT') {
            this.addForm = {
              content: {
                title: this.addForm.content.value,
                value: this.addForm.content.value
              },
              type: this.addType,
              isOpen: this.addForm.isOpen,
              is_top: this.addForm.is_top,
            }
          }
          const add = await postCommunityContent(this.addForm)
          if (add.code === 0) {
            //刷新列表
            await this.getCommunityInfo(this.pagination.current)
            //清空输入框
            this.$refs.addItem.resetFields();
            this.addForm = {
              isOpen: 1,
              is_top: 0,
              content: {}
            }
            //关闭对话框
            this.addShow = false;
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },


    promotionsClose() {
      this.promotionsVisible = false
      this.appData = []
      this.promotionId = null
      this.subContentType = null
    },
    async AppListSwitch(isOpen, row) {
      //推广
      if (isOpen) {
        let data = [
          {
            contentId: this.promotionId,//内容id
            contentType: "ARTICLE",//一级内容
            columnCode: row.code,//栏目
            subContentType: this.subContentType//子级内容
          }
        ]
        await this.postAppSwitch(data, row)
      } else { //关闭推广
        await this.delAppSwitch(this.promotionId, row.code, 'app', row)
      }
    },

    async delAppSwitch(id, code, type, row) {
      let data = {
        code: code,
        type: type
      }
      const response = await delAppPromotion(id, data)
      if (response.code === 0) {
        //更新数据
        if (type === 'app') {
          this.appData.forEach((a, index) => {
            if (a.code === row.code) {
              this.$set(this.appData, index, a)
              return
            }
          })
        }
        this.$message.success("关闭成功~")
      } else {
        this.$message.warning(response.message)
      }
    },
    async postAppSwitch(data, row) {
      const response = await postAppPromotion(data)
      if (response.code === 0) {
        //更新数据
        this.appData.forEach((a, index) => {
          if (a.code === row.code) {
            this.$set(this.appData, index, a)
            return
          }
        })
        this.$message.success("推广成功~")
      } else {
        this.$message.warning(response.message)
      }
    },

    async promotions(list) {
      this.promotionId = list.id
      this.subContentType = list.type
      //获取栏目
      this.getColumnList(list.id)
      //获取社区
      this.promotionsVisible = true
    },
    async getColumnList(id) {
      this.promotionSpin = true
      this.appData = []
      const response = await getColumnVodsList()
      if (response.code === 0) {
        this.appData = response.data
        await this.getAppList(id)
      } else {
        this.$message.warning(response.message)
      }
      this.promotionSpin = false
    },
    async getAppList(id) {
      const response = await getAppPromotion(id)
      if (response.code === 0) {
        this.columnListApp = response.data
        //对比
        let app = this.appData
        let columnList = this.columnListApp
        app.forEach((a, index) => {
          a.is_open = columnList.some(b => {
            return b.code === a.code
          })
          this.$set(app, index, a)
        })
      } else {
        this.$message.warning(response.message)
      }
    },


    //删除图片数组
    delImg(index) {
      this.addForm.content.images.splice(index, 1)
    },
    //删除
    delTable() {
    },
    delCancel() {
    },
  }
}
</script>

<style lang="scss" scoped>
.liBackground {
  color: #4a9bfa !important;
}

.communityContent {
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);

  .communityContent_header {
    background: white;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 15px;
  }

  .communityContent_center {
    margin-top: 10px;
    background: white;
    padding: 15px;

    .timeIndex {
      display: flex;
      cursor: pointer;
      align-items: center;

      .title {
        margin-right: 5px;
      }

      ::v-deep .anticon {
        color: #aba9a9;
        font-size: small;
      }
    }
  }
}

.uploadImg {
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  background-image: url("../../../assets/add.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.upload_gutter {
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
}
</style>
